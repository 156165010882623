import { autocomplete } from '@algolia/autocomplete-js';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import historyRouter from 'instantsearch.js/es/lib/routers/history';
import {
  connectInfiniteHits,
  connectSearchBox,
  connectRefinementList,
} from 'instantsearch.js/es/connectors';
import { configure, hits, index } from 'instantsearch.js/es/widgets';

import MagazineIcon from '../../images/application_2021/icon-magazine.svg';
import PdfIcon from '../../images/application_2021/icon-pdf.svg';
import SuppliesIcon from '../../images/application_2021/icon-supplies.svg';
import VideoIcon from '../../images/application_2021/icon-video.svg';

const body = document.body;
const header = document.getElementById('header');
const overlay = document.getElementById('global-search--overlay');
const closeButton = document.getElementById('global-search-close-button');
const closeButton2 = document.getElementById('global-search-close-button-2');
const searchContainer = document.getElementById('global-search-modal');
const searchField = document.getElementById('global-search-input');
const algoliaInit = document.getElementById('algolia-search-init');
const dynamicYieldDiv = document.getElementById('dynamic_yield_no_global_search_results');
const searchIndexName = algoliaInit.dataset.indexName;
const appKey = algoliaInit.dataset.appKey;
const searchClient = algoliasearch('CWUT1DIRGC', appKey);

const virtualSearchBox = connectSearchBox(() => {});
const instantSearchRouter = historyRouter();
const globalSearch = instantsearch({
  searchClient,
  indexName: searchIndexName,
  routing: instantSearchRouter,
  searchFunction: function (helper) {
    if (helper.state.query.length < 2) {
      return;
    }
    helper.search();
    hideSearchIcon();
  },
});

let lastRenderArgs;

const infiniteHits = connectInfiniteHits((renderArgs, isFirstRender) => {
  const { hits, widgetParams } = renderArgs;
  const { container } = widgetParams;
  const showMoreButton = document.getElementById('search-show-more');

  lastRenderArgs = renderArgs;

  if (isFirstRender) {
    const unorderedList = document.createElement('ul');
    unorderedList.classList.add('search__hits-list');
    container.appendChild(unorderedList);
    const button = document.createElement('button');
    button.setAttribute('id', 'search-show-more');
    button.classList.add(
      'button',
      'button--primary',
      'search__all-products-button'
    );
    container.appendChild(button);

    return;
  }

  const productList = container.querySelector('ul');
  if (!isFirstRender) {
    showMoreButton.innerHTML = `${I18n.t('search_product.all_products', {
      locale: MkBrowserLocale.get(),
    })} (${lastRenderArgs.results.nbHits})`;
    showMoreButton.addEventListener('click', function () {
      window.location = `/search?${searchIndexName}%5Bquery%5D=${renderArgs.results.query}`;
    });
    showMoreButton.classList.remove('search__all-products-button--hide');
  }
  if (!isFirstRender && lastRenderArgs.results.nbHits == 0) {
    showMoreButton.classList.add('search__all-products-button--hide');
    productList.innerHTML = `${I18n.t('search_product.no_product_results', {
      locale: MkBrowserLocale.get(),
      query: lastRenderArgs.results.query,
    })}`;
    productList.classList.add('search__error-message');
    dynamicYieldDiv.classList.remove('hidden');
  }

  if (!isFirstRender && renderArgs.results.query == '') {
    showMoreButton.classList.add('search__all-products-button--hide');
    productList.innerHTML = `${I18n.t('search_product.enter_search_term', {
      locale: MkBrowserLocale.get(),
    })}`;
    productList.classList.add('search__error-message');
  }

  if (
    !isFirstRender &&
    renderArgs.results.query != '' &&
    lastRenderArgs.results.nbHits > 0
  ) {
    productList.classList.remove('search__error-message');
    dynamicYieldDiv.classList.add('hidden');
    productList.innerHTML = hits
      .map(
        (hit) =>
          `<a class= "search__item" href="${hit.object_url}">
              <div class="search__item__image-container">
                <div class="product__image"><img alt="${hit.title}" src="${
            hit.catalog_image
          }"/></div>
              </div>
              <div class="search__item-right">
                <div>
                  <div class="search__item__title">${hit.title}</div>
                  <div class="search__item__creator">${
                    hit.creator_name || ''
                  }</div>
                </div>
                <div class="search__item-bottom">
                  <div class="product__type"><img src="${productTypeImage(
                    hit.searchable_type
                  )}"/></div>
                  <div class="product__price-container">${productPrice(
                    hit
                  )}</div>
                </div>
              </div>
            </a>
            `
      )
      .join('');
  }
});

const customRefinementList = connectRefinementList(categoryFilterLinks);

globalSearch.addWidgets([
  virtualSearchBox({}),
  configure({
    hitsPerPage: 3,
    typoTolerance: true,
    facetsRefinements: {
      locale: [MkBrowserLocale.get()],
    },
    facets: ['locale'],
  }),
  infiniteHits({
    container: document.querySelector('#global-search-product-hits'),
  }),
  customRefinementList({
    containerNode: '#global-search-category-links',
    attribute: 'navigation_path',
    limit: 5,
  }),
]);

globalSearch.start();

function setInstantSearchUiState(indexUiState) {
  globalSearch.setUiState((uiState) => ({
    ...uiState,
    [searchIndexName]: {
      ...uiState[searchIndexName],
      page: 1,
      ...indexUiState,
    },
  }));
}

function getInstantSearchUiState() {
  const uiState = instantSearchRouter.read();
  return (uiState && uiState[searchIndexName]) || {};
}

const searchPageState = getInstantSearchUiState();
autocomplete({
  container: '#global-search-input',
  placeholder: I18n.t('search_product.product_search', {
    locale: MkBrowserLocale.get(),
  }),
  detachedMediaQuery: 'none',
  initialState: {
    query: searchPageState.query || '',
  },
  onSubmit({ state }) {
    setInstantSearchUiState({ query: state.query });
    window.location = `/search?${searchIndexName}%5Bquery%5D=${state.query}`;
  },
  onReset() {
    setInstantSearchUiState({ query: '' });
    showSearchIcon();
  },
  onStateChange({ prevState, state }) {
    if (prevState.query !== state.query) {
      setInstantSearchUiState({ query: state.query });
    }
  },
});

searchField.addEventListener('click', function () {
  let inputLength = document.getElementById('autocomplete-0-input').value
    .length;
  if (!searchContainer.classList.contains('open') && inputLength > 1) {
    openSearch();
  }
});

searchField.addEventListener('keydown', function (event) {
  let input = document.getElementById('autocomplete-0-input');
  let inputLength = input.value.length;
  if (!searchContainer.classList.contains('open') && inputLength > 0) {
    openSearch();
  }
  if (inputLength >= 0 && !(event.key === 'Backspace')) {
    hideSearchIcon();
  }
});

searchField.addEventListener('keyup', function () {
  let inputLength = document.getElementById('autocomplete-0-input').value
    .length;
  if (inputLength === 0) {
    showSearchIcon();
  }
});

body.addEventListener('keydown', function (event) {
  if (event.key === 'Escape') {
    if (searchContainer.classList.contains('open')) {
      closeSearch();
    }
  }
});

overlay.addEventListener('click', function () {
  closeSearch();
});

if (closeButton != null) {
  closeButton.addEventListener('click', function () {
    closeSearch();
  });
}

if (closeButton2 != null) {
  closeButton2.addEventListener('click', function () {
    closeSearch();
  });
}

function openSearch() {
  body.classList.add('overlay__noscroll');
  overlay.classList.remove('hide');
  header.classList.add('open');
  searchContainer.classList.add('open');

  // hide trusted shops badge
  const badge = document.querySelectorAll('[id^=trustbadge-container]');
  if (badge.length > 0) {
    badge[0].style.display = 'none';
  }
}

function closeSearch() {
  body.classList.remove('overlay__noscroll');
  overlay.classList.add('hide');
  header.classList.remove('open');
  searchContainer.classList.remove('open');
}

function showSearchIcon() {
  document
    .getElementById('autocomplete-0-input')
    .parentElement.classList.remove('hide-search-icon');
}

function hideSearchIcon() {
  document
    .getElementById('autocomplete-0-input')
    .parentElement.classList.add('hide-search-icon');
}

function productPrice(hit) {
  const item_price = formatPrice(hit.gross_price_cents);
  const sale_price = formatPrice(hit.sale_gross_price_cents);

  return hit.sale_mode
    ? `<div class="product__price product__price--sale">${sale_price}</div>
  <div class="product__old-price">${item_price}</div>`
    : `<div class="product__price">${item_price}</div>`;
}

function formatPrice(price) {
  if (price === 0.0) {
    return I18n.t('search_product.price.free');
  }
  price = Number(price / 100.0);
  const locale = MkBrowserLocale.get();
  const currency = locale === 'en' ? 'USD' : 'EUR';
  return price.toLocaleString(locale, { style: 'currency', currency });
}

function productTypeImage(searchable_type) {
  switch (searchable_type) {
    case 'Pattern':
      return PdfIcon;
    case 'Material':
      return SuppliesIcon;
    case 'Course':
      return VideoIcon;
    case 'Shop::Product':
      return SuppliesIcon;
    default:
      return MagazineIcon;
  }
}

function categoryFilterLinks(renderOptions, isFirstRender) {
  const { containerNode } = renderOptions.widgetParams;

  if (renderOptions.canRefine) {
    const list = renderOptions.items.map(function (item) {
      const link_split = item.value.split('/');
      const link =
        link_split[2] +
        (link_split[3] === undefined ? '' : ` | ${link_split[3]}`);
      return `<li><a href='${item.value}'>${link}</a></li>`;
    });

    document.querySelector(containerNode).innerHTML = `
      <ul>
        ${list
          .map(
            (item) => `
            ${item}`
          )
          .join('')}
      </ul>
    `;
  }
}
